import { useState, createContext, useContext, ReactNode, useEffect } from "react";
import { fetchAndActivate, getAll } from 'firebase/remote-config';
import { remoteConfig } from "../firebase";

export const RemoteConfigContext = createContext<RemoteConfigContextType | null>(null);

interface RemoteConfigContextType {
    config: { [key: string]: string }
}

const DataContext = (props: { children: ReactNode }) => {
    const [config, setConfig] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        const fetchConfig = async () => {
            const rcDefaultsFile = await import('../remote_config_defaults.json');

            remoteConfig.defaultConfig = rcDefaultsFile.default;

            await fetchAndActivate(remoteConfig);
            const allValues = getAll(remoteConfig);
            const configValues: { [key: string]: string } = {};

            Object.keys(allValues).forEach(key => {
                configValues[key] = allValues[key].asString();
            });

            setConfig(configValues);
        };

        fetchConfig();
    }, []);


    return (
        <RemoteConfigContext.Provider value={{ config: config }}>
            {props.children}
        </RemoteConfigContext.Provider>
    );
}

export default DataContext